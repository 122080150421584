import './main.css'

const Recron = ({ title, image_url, url, logo_url, usps, regio, usps_marker}) => {
  const fixedUrl = url.replace(/\/$/, '') + '/'

  return <div className="object">
    <div className="object-image">
      <a href={fixedUrl} rel="detail" target="_blank">
        <img src={image_url} />
      </a>
    </div>
    <div className="object-section">
      <div className="object-logo"><img src={logo_url} /></div>
      <div className="object-title-header" rel="detail" target="_blank">
        <h1 className="object-title">
          <a href={fixedUrl} rel="detail" target="_blank">{title}</a>
        </h1>
        <ul className="object-title-breadcrumb">
          <li>Nederland</li>
          <li>{regio}</li>
        </ul>
      </div>
      <a href={fixedUrl} className="object-description" rel="detail" target="_blank">
        <ul className="object-usp-list">
          {
            usps.map((usp) => <li key={usp}><img src={usps_marker} />{usp}</li>)
          }
        </ul>
        <strong>Meer informatie</strong>
      </a>
    </div>
  </div>
}

export default Recron
