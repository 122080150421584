import React from 'react'
import axios from 'axios'
import { MapContainer, TileLayer, Marker, Tooltip, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
import L from 'leaflet'
import Icon from '../assets/icon.png'
import RecronTemplate from '../templates/recron/main'

const BASE_URL = 'https://www.mijnhiswarecron.nl/myhiswa/endpoint/my/recron-map?type=json'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

const icon = urlParams.get('icon')

var defaultIcon = L.icon({
  iconUrl: icon || Icon,
  iconSize: [24, 36],
  iconAnchor:   [12, 36]
})

var icons = [];

L.Marker.prototype.options.icon = defaultIcon

var assets = null;
var object = null;

export const convertUrlObjectToSearchParams = (objects) => {
  return `objects=${objects}`
}

const getMarkerData = async (objects) => {
  try {
    const response = await axios.get(`${BASE_URL}&${objects}`)
    const { data } = response
    return data
  } catch (error) {
    console.error('errror: ', error)
    return []
  }
}

const Maps = () => {
  const [deviceType, setDeviceType] = React.useState(null)
  const [markerData, setMarkerData] = React.useState(null)
  const [center, setCenter] = React.useState()
  const [zoom, setZoom] = React.useState(13)
  const [maxZoom, setMaxZoom] = React.useState(13)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getMapMarkers = async () => {
      object = urlParams.get('object')
      var objects = urlParams.get('objects')
      const lat = urlParams.get('lat')
      const lon = urlParams.get('lon')
      const zoom = urlParams.get('zoom')
      const maxZoom = urlParams.get('maxZoom')
      const mode = urlParams.get('mode')

      /*if (maxZoom) {
        setMaxZoom(maxZoom)
      }

      if (zoom) {
        setZoom(zoom)
      }

      if (lat && lon) {
        setCenter([lat, lon])
      }*/
      if(object){
        if(objects){
          objects += ','+object;
        }
        else{
          objects = object;
        }
      }

      if (objects) {
        const objectsConvertedToArray = convertUrlObjectToSearchParams(objects)

        var additional_params = '';
        if(mode){
          additional_params += "&mode="+mode;
        }

        setLoading(true)
        const markerResponse = await getMarkerData(objectsConvertedToArray+additional_params)
        setLoading(false)

        const { options: { maxZoom, zoom }} = markerResponse

        assets = markerResponse.assets

        if(assets['icons']){
          
          for(var key in assets['icons']){
            var value = assets['icons'][key];

            console.log(value);
            icons[key] = L.icon({
              iconUrl: value.src,
              iconSize: value.size,
              iconAnchor: value.anchor
            })
          }
        }

        if(icons['default']){
          defaultIcon = icons['default'];
        }

        //setZoom(zoom)
        setMaxZoom(maxZoom)

        setMarkerData(markerResponse)

        /*const markerBounds = getMapPositions()

        console.log(markerBounds);

        if (markerBounds && markerBounds.length > 0 && !center) {
          fitBounds(markerBounds)
        }*/
      }
    }

    if (!deviceType) {
      let hasTouchScreen = false;
      if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
          hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
          hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          var UA = navigator.userAgent;
          hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
      }
      if (hasTouchScreen) {
        setDeviceType('Mobile');
      } else {
        setDeviceType('Desktop');
      }
    }

    getMapMarkers()
  }, [])

  if (loading) {
    return <div style={{
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Loader type='Bars' color='#00BFFF' height={30} width={30} />
    </div>
  }

  if (!markerData) {
    return null
  }

  const { markers } = markerData

  const getMapPositions = () => {
    if (markerData) {
      return markers.map(marker => marker.position)
    }
    return []
  }

  return <MapContainer
    className='markercluster-map'
    center={center}
    zoom={zoom}
    tap={false}
    maxZoom={maxZoom}
    whenCreated={(map) => {
      const markerBounds = getMapPositions()
      if (markerBounds && markerBounds.length > 0 && !center) {
        map.fitBounds(markerBounds)
      }
    }}
  >
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    />
    <MarkerClusterGroup showCoverageOnHover={false} maxClusterRadius={40}>
      {
        markers.map((marker, index) => {
          if(marker.object_id != object){
            return <Marker key={index} position={marker.position} icon={(marker.icon && icons[marker.icon]) ? icons[marker.icon] : defaultIcon}>
              {deviceType === 'Desktop' ? <Tooltip>{marker.tooltip}</Tooltip> : null}
              <Popup>
                {
                  marker.type === 'recron'
                  ? <RecronTemplate
                    {...marker.template_vars}
                    title={marker.tooltip}
                    usps_marker={assets.usps_marker}
                  />
                  : <div dangerouslySetInnerHTML={{ __html: marker.popup }} />
                }
              </Popup>
            </Marker>
          }
        })
      }
    </MarkerClusterGroup>

    {
    markers.map((marker, index) => {
      if(marker.object_id == object){
        return <Marker key={index} position={marker.position} icon={defaultIcon}>
          {deviceType === 'Desktop' ? <Tooltip>{marker.tooltip}</Tooltip> : null}
          <Popup>
            {
              marker.type === 'recron'
              ? <RecronTemplate
                {...marker.template_vars}
                title={marker.tooltip}
                usps_marker={assets.usps_marker}
              />
              : <div dangerouslySetInnerHTML={{ __html: marker.popup }} />
            }
          </Popup>
        </Marker>
      }
    })
  }

  </MapContainer>
}

export default Maps
